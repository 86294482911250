import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  
    props:{
      MuiButtonBase: {
        disableRipple:true,
      }
    
  },

    palette: {
      primary:{
          main: "#3D55A4",
      },
      background:{
        default: "#fff",
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1280,
        xl: 1550,
        xxl:1920,
      },
    },
    overrides:{
      MuiGrid:{
        container:{
          width: "100% !important",
          marginLeft: "0 !important",
          marginRight: "0 !important"
        }
      },
      MuiTab:{
        root:{
          minWidth:"80px",
          '@media(min-width:768px)': {
            minWidth:"80px",
          }
        }
      }
    }
    

  })

  export default theme;