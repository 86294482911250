import React from 'react';
import TopLayout from './themeProvider';
// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
// Highlighting for code blocks
//import "prismjs/themes/prism.css"

export const wrapRootElement = ({ element }) => {
    return <TopLayout>{element}</TopLayout>;
};
